import * as React from "react"
import Layout from "../components/common/Layout"
import { routes } from "../page-data/routes"
import SEO from "../components/seo"

const iframeStyle = {
  height: "calc(100vh - 125px + 320px)",
  margin: "0",
  padding: "0",
  border: "none",
}

const App = () => {
  return (
    <Layout>
      <SEO title="Overview" />
      <iframe
        className="overview-iframe"
        style={iframeStyle}
        src={routes.shiny.overview}
        height="100%"
        width="100%"
      />
    </Layout>
  )
}

export default App
